<template>
  <div>
    <form
      id="payment-form"
      action=""
      method="post"
      @submit.prevent="handleCardInformation"
    >
      <div id="card-element">
        <!-- A Stripe Card will be inserted here. -->
      </div>
      <div
        :style="cardErrors ? '' : 'margin-top: 24px;'"
        role="alert"
      >
        {{ cardErrors }}
      </div>
      <div style="display:flex;margin-top:6px">
        <button
          v-show="showCancel"
          class="button actionButton force-right"
          @click="cancelAction"
        >
          Back
        </button>
        <button
          :class="`button is-primary actionButton ${showCancel ? '' : 'force-right'}`"
          type="submit"
        >
          {{ submitBtnText }}
        </button>
      </div>
    </form>
    <b-loading
      :is-full-page="false"
      :active="isAddingCard"
      :can-cancel="false"
    />
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import EnvironmentSetter from '@/helpers/environmentSetter';

const addCardStyles = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

export default {
  name: 'GetCardInfo',
  props: {
    submitBtnText: {
      type: String,
      default: '',
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    onCancel: {
      type: Function,
      default() { },
    },
    onSuccess: {
      type: Function,
      default() { },
    },
  },
  data() {
    return {
      stripe: null,
      card: null,
      addCardStyles,
      isAddingCard: false,
      cardErrors: '',
    };
  },
  async mounted() {
    if (this.$isBgCloud) {
      // Create a Stripe client.
      this.stripe = await loadStripe(EnvironmentSetter.getEnvironmentByIndex('VUE_APP_STRIPE_PK'), {
        locale: 'en',
      });

      // Create an instance of the card Element.
      const elements = this.stripe.elements();
      this.card = elements.create('card', { style: this.addCardStyles });
      this.card.mount('#card-element');
      const thisComponent = this;

      // Handle real-time validation errors from the card Element.
      this.card.addEventListener('change', (event) => {
        if (event.error) thisComponent.cardErrors = event.error.message;
        else thisComponent.cardErrors = '';
      });
    }
  },
  methods: {
    cancelAction(e) {
      e.preventDefault();
      this.card.clear();
      this.onCancel();
    },
    handleCardInformation() {
      this.isAddingCard = true;
      const thisComponent = this;
      this.stripe.createSource(this.card, {
        type: 'card',
        currency: 'usd',
      }).then((result) => {
        if (result.error) {
          // Inform the user if there was an error.
          thisComponent.cardErrors = result.error.message;
        } else {
          thisComponent.card.clear();
          thisComponent.onSuccess(result.source);
        }
        thisComponent.isAddingCard = false;
      }).catch((error) => { throw new Error(error); });
    },
  },
};
</script>

<style>
.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.actionButton {
  margin-right: 6px;
}
</style>
