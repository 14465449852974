import { mapActions } from 'vuex';
import vtulEnums from '../../../cross/index';

const { stripeQuery } = vtulEnums.enum.stripe;

export default {
  name: 'StripeCompanyInformationMixin',
  data() {
    return {
      freePlan: false,
      subscription: null,
    };
  },
  created() {
    this.fetchCurrentPlan();
  },
  methods: {
    ...mapActions('stripe-company-information', { findCurrentPlan: 'find' }),
    async fetchCurrentPlan() {
      if (!this.$isBgCloud) {
        return;
      }
      const subscription = await this.findCurrentPlan({
        query: {
          stripeQuery: stripeQuery.CURRENTSUBSCRIPTION.value,
          customerId: this.currentCompany.stripeCustomerId,
          companyId: this.currentCompany.id,
        },
      });
      if (subscription[0].plan && subscription[0].plan.metadata.FREE_PLAN === 'true') {
        this.freePlan = true;
      }
      this.subscription = subscription;
    },
  },
};
