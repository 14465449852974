<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Billing
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <card
      style="padding-top:12px"
      external-card="material-card-content"
    >
      <div
        slot="external-header"
        class="columns is-desktop"
      >
        <card
          class="column m-3"
          external-card="card material-card material-card-content"
          url-help=""
        >
          <p
            slot="header"
            class="subtitle"
          >
            Active plan
          </p>
          <div
            slot="body"
            class="is-tablet"
          >
            <div>
              <div style="text-align:center;">
                <div v-if="isInTrial">
                  <p style="font-size: 20px;font-weight: 300;">
                    {{ trialDaysRemaing > 1 ? "Your trial ends in" : "Your trial has ended" }}
                  </p>
                  <p
                    v-show="trialDaysRemaing > 1"
                    style="font-size: 30px;font-weight: 400;"
                  >
                    {{ trialDaysRemaing }} days
                  </p>
                </div>
                <div v-else-if="!haveActiveSubscription">
                  <p style="font-size: 20px;font-weight: 300;">
                    You don't have any active plan
                  </p>
                  <br>
                  <router-link
                    :to="`/${$route.params.companyId}/upgradeplan`"
                    style="margin-top: 6px;"
                    class="button is-primary"
                  >
                    Go To Plans
                  </router-link>
                </div>
                <div v-else>
                  <p
                    v-if="freePlan"
                    style="font-size: 30px;font-weight: 400;"
                  >
                    Free
                  </p>
                  <p
                    v-else-if="plan && !priceValue"
                    style="font-size: 30px;font-weight: 400;"
                  >
                    ${{ fixedTotalPrice }} USD
                  </p>
                  <p
                    v-else-if="priceValue"
                    style="font-size: 30px;font-weight: 400;"
                  >
                    {{ priceValue }}
                  </p>
                  <p
                    v-else
                    style="font-size: 25px;font-weight: 400;"
                  >
                    You have an active subscription
                  </p>
                  <p
                    v-if="plan && !freePlan && !customPlan"
                    style="font-size: 20px;font-weight: 300;"
                  >
                    Billed {{ plan.interval.toLowerCase() === 'month' ? 'monthly' : 'yearly' }}
                  </p>
                  <button
                    v-if="freePlan"
                    style="margin-top: 6px;"
                    class="button is-primary"
                    @click="upgradeSubscription"
                  >
                    Upgrade Now
                  </button>
                  <p
                    v-if="customPlan"
                    style="font-size: 20px;font-weight: 400;"
                  >
                    Used licenses: {{ totalDevices }}
                    / {{ getTotalDevicesForSubscription() }}
                    <b-loading
                      :active="totalDevices === null"
                      :is-full-page="false"
                      :can-cancel="false"
                    />
                  </p>
                </div>
                <button
                  v-if="plan && !freePlan && customPlan !== null && !customPlan"
                  style="margin-top: 16px;"
                  class="button is-danger is-small"
                  @click="cancelSubscription"
                >
                  Cancel Subscription
                </button>
              </div>
            </div>
          </div>
        </card>
        <card
          class="column m-3"
          external-card="card material-card material-card-content"
          url-help=""
        >
          <p
            slot="header"
            class="subtitle"
          >
            Credit cards
          </p>
          <section
            slot="body"
          >
            <div
              v-show="!showAddCard"
            >
              <div
                v-for="item in cards"
                :key="item.id"
                style="margin:4px"
              >
                <div style="display: inline-flex;">
                  <div
                    class="cardSelection"
                    style="margin-right: 6px;"
                  >
                    <b-icon
                      v-show="item.id === defaultCardId"
                      style="margin-bottom:4px"
                      icon="check-circle"
                      type="is-success"
                    />
                    <a
                      v-show="item.id !== defaultCardId"
                      style="color:#4a4a4a"
                      @click="changeDefaultCard(item.id)"
                    >
                      <b-icon
                        icon="checkbox-blank-circle-outline"
                      />
                    </a>
                  </div>
                  <div style="float: left;">
                    <b-icon
                      icon="credit-card-outline"
                    />
                    <span style="margin: 0px 15px;">XXXX-XXXX-XXXX-{{ item.card.last4 }}</span>
                  </div>
                  <div class="cardSelection">
                    <a
                      v-show="item.id !== defaultCardId"
                      @click="removeCard(item)"
                    >
                      <b-icon
                        class="removeCard"
                        icon="delete"
                        type="is-danger"
                      />
                    </a>
                  </div>
                </div>
                <b-loading
                  :is-full-page="isFullLoading"
                  :active="isLoading"
                  :can-cancel="false"
                />
              </div>
              <div
                v-show="cards.length < 1"
                style="margin:36px 0px;"
              >
                <div style="display: -webkit-inline-box;">
                  <b-icon
                    icon="credit-card-outline"
                  />
                  <p style="margin: 0px 15px;">
                    There are no credit cards registered
                  </p>
                </div>
              </div>
              <button
                style="margin-top: 24px;"
                class="button is-primary force-right force-bottom"
                @click="showAddCardForm()"
              >
                Add a new card
              </button>
            </div>
            <div
              v-show="showAddCard"
              style="height: 100%;overflow: hidden; padding: 16px"
            >
              <getCardInfo
                :submit-btn-text="'Save Card'"
                :show-cancel="true"
                :on-cancel="cancelAddCard"
                :on-success="successAddCard"
              />
            </div>
          </section>
        </card>
      </div>
      <card
        v-if="customPlan !== null && !customPlan"
        slot="external-body"
        internal-card="card material-card material-card-content"
        url-help=""
      >
        <p
          slot="header"
          class="subtitle"
        >
          Transactions
        </p>
        <b-table
          slot="body"
          :data="transactions"
          :mobile-cards="false"
          :paginated="transactions && transactions.length > maxQuantity"
          :per-page="maxQuantity"
          default-sort="date"
          default-sort-direction="desc"
        >
          <b-table-column
            field="total"
            label="Amount"
            sortable
          >
            <template v-slot="props">
              {{ (props.row.total / 100) | currency }}
            </template>
          </b-table-column>

          <b-table-column
            field="paid"
            label="Status"
            sortable
          >
            <template v-slot="props">
              {{ props.row.paid ? 'Paid' : 'Not Paid' }}
            </template>
          </b-table-column>

          <b-table-column
            field="number"
            label="Invoice number"
            sortable
          >
            <template v-slot="props">
              {{ props.row.number }}
            </template>
          </b-table-column>

          <b-table-column
            field="date"
            label="Date"
            sortable
          >
            <template v-slot="props">
              {{ (props.row.date * 1000) | moment }}
            </template>
          </b-table-column>

          <b-table-column
            field="invoice_pdf"
            label=""
          >
            <template v-slot="props">
              <b-button
                type="is-white"
                @click="downloadInvoice(props.row.invoice_pdf)"
              >
                <b-icon
                  type="is-primary"
                  icon="cloud-download"
                />
              </b-button>
            </template>
          </b-table-column>

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon
                    icon="file"
                    size="is-large"
                  />
                </p>
                <p>There are no transactions to show</p>
              </div>
            </section>
          </template>
        </b-table>
      </card>
    </card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';

import getCardInfo from '@/components/billing/GetCardInfo.vue';
import confirmation from '@/components/cross/ModalConfirmation.vue';
import Subscription from '@/helpers/subscription';
import card from '@/components/cross/Card.vue';

import ErrorMixin from '@/mixins/error';
import analyticsMixin from '@/mixins/analytics';
import StripeCompanyInformationMixin from '@/mixins/stripeCompanyInformation';

import snackBarMessage from '@/helpers/snackBarMessage';
import crossHelper from '@/helpers/cross';

import vtulEnums from '../../../cross/index';

const { operationalUiErrors } = vtulEnums.enum.ui;
const { alertBox } = vtulEnums.enum.alert;
const { stripeQuery } = vtulEnums.enum.stripe;

export default {
  name: 'Billing',
  metaInfo: {
    title: 'Billing',
  },
  components: {
    getCardInfo,
    card,
  },
  mixins: [ErrorMixin, StripeCompanyInformationMixin, analyticsMixin],
  data() {
    return {
      transactions: [],
      cards: [],
      maxQuantity: 40,
      defaultCardId: null,
      availableBalance: 0,
      showAddCard: false,
      isLoading: false,
      trialDaysRemaing: null,
      isInTrial: false,
      isFullLoading: false,
      isNotAdmin: false,
      modalAuth: undefined,
      priceValue: null,
      customPlan: null,
      plan: null,
      fixedTotalPrice: null,
      totalDevices: null,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    user() {
      return this.$store.getters['auth/user'];
    },
    haveActiveSubscription() {
      return Subscription.isActive(this.currentCompany);
    },
  },
  watch: {
    subscription: {
      handler() {
        if (this.subscription) {
          this.fetchData();
        }
      },
    },
  },
  created() {
    this.getTotalDevicesFromApi();
  },
  destroyed() {
    if (this.modalAuth) {
      this.modalAuth.close();
    }
  },
  methods: {
    ...mapActions('devices', { findDevices: 'find' }),
    ...mapActions('stripe-company-information', { findStripeCustomerInfo: 'find', updateCustomer: 'patch' }),
    ...mapActions('companies', { updateCompany: 'patch' }),
    ...mapActions('companies', ['changeCurrentCompany']),
    ...mapMutations(['clearCurrentSelections']),
    showAddCardForm() {
      this.showAddCard = true;
    },
    cancelAddCard() {
      this.showAddCard = false;
    },
    successAddCard(source) {
      this.trackEvent(!this.currentCompany.isTestData && !this.user.isTestData,
        'track', 'Add Card', {
          brand: source.card.brand,
          country: source.card.country,
          cvc_check: source.card.cvc_check,
          funding: source.card.funding,
          name: source.card.name,
          tokenization_method: source.card.tokenization_method,
        });
      this.showAddCard = false;
      this.isLoading = true;
      this.updateCustomer([this.currentCompany.stripeCustomerId, {
        sourceId: source.id,
        forcePay: false,
        companyId: this.currentCompany.id,
      }, {}]).then((data) => {
        this.cards.push({
          id: data.id,
          card: data.card,
        });
        snackBarMessage.showSuccess('The card was added successfully.');
        if (this.cards.length === 1) {
          this.defaultCardId = data.id;
        }

        this.showAddCard = false;
        this.isLoading = false;
      }).catch((err) => {
        this.showAddCard = false;
        this.isLoading = false;
        this.throwVtulError(operationalUiErrors.PAYMENT_CARD_ERROR, alertBox.SNACKBAR.value, err);
      });
    },
    changeDefaultCard(newSourceId) {
      this.isLoading = true;
      this.updateCustomer([this.currentCompany.stripeCustomerId, {
        defaultSourceId: newSourceId,
        companyId: this.currentCompany.id,
      }, {}]).then(() => {
        this.defaultCardId = newSourceId;
        this.isLoading = false;
        snackBarMessage.showSuccess('The default payment method was changed.');
      }).catch((err) => {
        this.isLoading = false;
        this.throwVtulError(operationalUiErrors.ERROR_CHANGE_PAYMENT_METHOD,
          alertBox.SNACKBAR.value, err);
      });
    },
    removeCard(currentCard) {
      const thisComponent = this;
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          title: 'Remove Card',
          message: `Are you sure to want remove the card ended on <b>${currentCard.card.last4}</b>?`,
          confirmText: 'Yes',
          cancelText: 'No',
          onConfirm: () => {
            thisComponent.isLoading = true;
            thisComponent.updateCustomer([thisComponent.currentCompany.stripeCustomerId, {
              sourceId: currentCard.id,
              detachSource: true,
              companyId: thisComponent.currentCompany.id,
            }, {}]).then(() => {
              thisComponent.removeUICard(currentCard.id);
              thisComponent.isLoading = false;
              snackBarMessage.showSuccess('The card was removed.');
            }).catch((err) => {
              thisComponent.isLoading = false;
              this.throwVtulError(operationalUiErrors.REMOVE_CARD_ERROR,
                alertBox.SNACKBAR.value, err);
            });
          },
        },
      });
    },
    removeUICard(cardId) {
      const removedCardIndex = this.cards.findIndex((x) => x.id === cardId);
      this.cards.splice(removedCardIndex, 1);
    },
    cancelSubscription() {
      const thisComponent = this;
      this.$buefy.modal.open({
        parent: this,
        component: confirmation,
        hasModalCard: true,
        props: {
          title: 'Cancel Subscription',
          message: 'Are you sure to want cancel your subscription?',
          confirmText: 'Cancel subscription',
          cancelText: 'Back',
          onConfirm: () => {
            this.trackEvent(!this.currentCompany.isTestData && !this.user.isTestData,
              'track', 'Cancel Subscription', {
                companyName: this.currentCompany.name,
                companyAddress: this.currentCompany.address,
                companyCity: this.currentCompany.city,
                companyCountry: this.currentCompany.country,
                companyStripeCustomerId: this.currentCompany.stripeCustomerId,
                companyUrl: this.currentCompany.url,
              });
            thisComponent.isFullLoading = true;
            thisComponent.isLoading = true;
            thisComponent.updateCustomer([this.currentCompany.stripeCustomerId, {
              cancelSubscription: true,
              companyId: this.currentCompany.id,
            }, {}]).then(() => {
              snackBarMessage.showSuccess('The subscription was canceled.');
              thisComponent.updateCompany([this.currentCompany.id, {
                stripeExpirationDate: new Date(),
              }, {}]).then((company) => {
                thisComponent.clearCurrentSelections();
                thisComponent.changeCurrentCompany(company);
                thisComponent.isFullLoading = false;
                thisComponent.isLoading = false;
                thisComponent.$router.push(`/${company.id}/devices`);
              }).catch((err) => {
                this.throwVtulError(operationalUiErrors.UPDATE_COMPANY_ERROR,
                  alertBox.SNACKBAR.value, err);
              });
            }).catch((err) => {
              thisComponent.isFullLoading = false;
              thisComponent.isLoading = false;
              this.throwVtulError(operationalUiErrors.CANCEL_SUBCRIPTION_ERROR,
                alertBox.SNACKBAR.value, err);
            });
          },
        },
      });
    },
    async getTotalDevicesFromApi() {
      if (!this.currentCompany) return;
      const { total } = await this.findDevices({
        query: {
          $limit: 0,
          companyId: this.currentCompany.id,
        },
      });
      this.totalDevices = total;
    },
    getTotalDevicesForSubscription() {
      if (!this.subscription || this.subscription.length < 1) return 0;
      const { quantity } = this.subscription[0].activeSubscription;
      if (quantity !== null) {
        return quantity;
      }
      const activeItem = this.subscription[0].activeSubscription.items.data
        .find((x) => Object.keys(x.plan.metadata).length !== 0);
      if (activeItem) {
        return activeItem.quantity;
      }
      return 0;
    },
    async fetchData() {
      if (!this.currentCompany) return;
      this.availableBalance = this.subscription[0].customer.account_balance;
      if (this.subscription[0].activeSubscription) {
        const totalPrice = this.getTotalDevicesForSubscription()
          * parseFloat(this.subscription[0].plan.metadata.PRICE);
        this.fixedTotalPrice = totalPrice.toFixed(2);
      }
      this.cards = this.subscription[0].customer.sources.data.filter((x) => x.type === 'card');
      this.defaultCardId = this.subscription[0].customer.default_source;
      let trialPlan = this.subscription[0].customer.subscriptions.data.find((x) => x.status === 'trialing');
      // deviceLimit is 0 for all the plans that not are free
      if (this.subscription[0].plan) {
        this.plan = this.subscription[0].plan;
        if (this.subscription[0].plan.metadata.HAS_DEVICE_LIMIT === 'true') {
          const planDeviceLimit = parseInt(this.subscription[0].plan.metadata.DEVICE_LIMIT, 10);
          this.customPlan = planDeviceLimit === 0;
          if (this.customPlan) {
            trialPlan = null;
          }
        }
      }
      if (this.customPlan === null) {
        this.customPlan = false;
      }
      const date = trialPlan ? moment(trialPlan.current_period_end * 1000) : null;
      if (!this.freePlan) {
        this.isInTrial = trialPlan;
      }
      const currentDate = moment(new Date()).add(2, 'minutes');
      this.trialDaysRemaing = date && currentDate ? (date.diff(currentDate, 'days') + 1) : null;
      if (this.subscription[0].plan) {
        const planDeviceLimit = parseInt(this.subscription[0].plan.metadata.DEVICE_LIMIT, 10);
        if (this.subscription[0].plan.metadata.HAS_DEVICE_LIMIT === 'true' && planDeviceLimit === 0) {
          this.priceValue = 'Custom';
        }
      }
      this.findStripeCustomerInfo({
        query: {
          stripeQuery: stripeQuery.INVOICES.value,
          customerId: this.currentCompany.stripeCustomerId,
          companyId: this.currentCompany.id,
        },
      }).then((invoices) => {
        this.transactions = invoices.data;
      }).catch((err) => {
        this.throwVtulError(operationalUiErrors.RETRIVE_TRANSACTION_ERROR,
          alertBox.SNACKBAR.value, err);
      });
    },
    upgradeSubscription() {
      this.trackEvent(!this.currentCompany.isTestData && !this.user.isTestData,
        'track', 'Upgrade to Paid Plan', {
          companyName: this.currentCompany.name,
          companyAddress: this.currentCompany.address,
          companyCity: this.currentCompany.city,
          companyCountry: this.currentCompany.country,
          companyStripeCustomerId: this.currentCompany.stripeCustomerId,
          companyUrl: this.currentCompany.url,
        });
      this.$router.push(`/${this.currentCompany.id}/upgradeplan`);
    },
    downloadInvoice(invoiceLink) {
      this.trackEvent(!this.currentCompany.isTestData && !this.user.isTestData,
        'track', 'Download Invoice', {
          companyName: this.currentCompany.name,
          companyAddress: this.currentCompany.address,
          companyCity: this.currentCompany.city,
          companyCountry: this.currentCompany.country,
          companyStripeCustomerId: this.currentCompany.stripeCustomerId,
          companyUrl: this.currentCompany.url,
        });
      crossHelper.openExternalLink(invoiceLink, true);
    },
  },
};
</script>

<style scoped>
.cardSelection {
  float: right;
  margin-left: auto;
  align-items: center;
  display: flex;
}
.preBillingCardColumn {
  border-right: 2px solid #ededed;
}
.removeCard:hover {
  color: #632d8e !important;
}
@media only screen and (max-width: 768px) {
  .preBillingCardColumn {
    border-right: 0px solid #ededed;
  }
}
</style>
